/* styles.css */

body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f5f5f5; /* Light gray background */
  color: #333;
}

.App {
  text-align: center;
  background-color: #007bff; /* Blue background color */
  color: #fff; /* White text color */
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.Name{
  /* background: rgb(15, 15, 38); */
  color: #f5f5f5;
  width: 40%;
  border-radius: 20PX;
  align-items: center;
  justify-content: center;
  margin-left: 2rem;
}

h1 {
  margin-bottom: 20px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Additional styles for CurrentWeather component */

h2 {
  color: #f3f5f8; /* Blue heading color */
}

p {
  margin: 10px 0;
  color: #555;
}

footer {
  margin-top: 20px;
  padding: 20px; /* Increased padding for better balance */
  /* background-color: #007bff; */
  color: #fff;
  text-align: center;
  margin-left: 35rem;
}

footer a {
  color: #fff;
  text-decoration: underline;
}

footer a:hover {
  color: #fff;
  text-decoration: none;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .current-weather-container {
    max-width: 100%;
  }
}
